
import $ph from '@/plugins/phoenix';
import { ILanguage, MenuItem } from '@/plugins/phoenix/types';
import { Component, Vue } from 'vue-property-decorator';
import { IAuthorisation, IRegistration, SessionState, User2FAModes, WalletmelonAccess } from './modules/session/types';
import { IScreenSize } from './modules/system/types';
import { Process2fa } from './modules/wallet/types';
import settings from './plugins/settings';
import { session, system, wallet } from './plugins/store';

@Component
export default class App extends Vue {
  processing = false;
  showChangePwd = false;
  showForgetPass = false;

  sfaShow = false
  sfaMode = User2FAModes.None
  sfaProcess = Process2fa.Check
  sfaRequired = false

  get showDashboard(): boolean {
    return system.showDashboard
  }

  get showPrices(): boolean {
    return system.showPrices
  }

  get showAccounts(): boolean {
    return system.showAccounts
  }

  get showTransactions(): boolean {
    return system.showTransactions && $ph.access(WalletmelonAccess.WalletTransactions)
  }

  get showStatement(): boolean {
    return system.showStatement && $ph.access(WalletmelonAccess.WalletStatement)
  }

  get showProcessing(): boolean {
    return system.showProcessing && $ph.access(WalletmelonAccess.WalletProcessing)
  }

  get operation() {
    return wallet.currentOperation
  }

  get showOperation(): boolean {
    return wallet.currentOperation != null
  }

  set showOperation(value: boolean) {
    if (!value) {
      wallet.setCurrentOperation(null)
    }
  }

  get previewData() {
    return wallet.currentPreview
  }

  get showPreview(): boolean {
    return wallet.currentPreview.visible
  }

  set showPreview(value: boolean) {
    if (!value) {
      wallet.setCurrentPreview(null)
    }
  }

  get notification() {
    return session.notification;
  }

  get showNotification() {
    return session.notification !== null;
  }

  set showNotification(value: boolean) {
    if (!value) {
      session.showNotification(null);
    }
  }

  get copyrights(): string {
    const year = String(new Date().getFullYear())
    const copyrigts = $ph.i18n('system.siteCopyrights')
    const operated = $ph.i18n('system.siteOperated')
    const docs = $ph.i18n('system.siteLegalDocs')

    return $ph.i18n(settings.company.companyCopyrights, year, copyrigts, operated, docs);
  }

  get copyrightsMini(): string {
    const year = String(new Date().getFullYear())
    const copyrigts = $ph.i18n('system.siteCopyrights')
    const operated = $ph.i18n('system.siteOperated')
    const docs = $ph.i18n('system.siteLegalDocs')

    return $ph.i18n(settings.company.companyCopyrightsMini, year, copyrigts, operated, docs);
  }

  get appMenu(): Array<MenuItem> {
    return this.filterMenu(system.menu).sort((a, b) => a.id - b.id)
  }

  filterMenu(menu: Array<MenuItem> | null) {
    return menu ? menu.filter(m => $ph.accessRoute(m.path)) : [];
  }

  get languages(): Array<ILanguage> {
    return system.languages
  }

  get currentLang(): ILanguage {
    return session.currentLanguage
  }

  set currentLang(lang: ILanguage) {
    if (this.isAfterLogin) {
      session.changeLanguage(lang.code)
    } else {
      session.setLanguage(lang.code)
      this.$router.go(0)
    }
  }

  get router() {
    return this.$router.getRoutes();
  }

  get instance(): string {
    return session.instance;
  }

  get appName(): string {
    return system.appName;
  }

  get version(): string {
    return system.version;
  }

  get isLoading(): boolean {
    return session.currentState === SessionState.Loading || session.loading
  }

  get isInitalLoad(): boolean {
    return session.currentState === SessionState.Loading
  }

  get isWaitAuth(): boolean {
    return session.currentState === SessionState.WaitAuth && !this.showForgetPass;
  }

  get isWait2fa(): boolean {
    return session.currentState === SessionState.PreAuthorized;
  }

  get isNotInitialized(): boolean {
    return session.currentState === SessionState.NotInitialized;
  }

  get isAuthorized(): boolean {
    return session.currentState === SessionState.Authorized;
  }

  get isPreAuthorizedAndUp(): boolean {
    return session.currentState === SessionState.Authorized ||
      session.currentState === SessionState.PreAuthorized;
  }

  get isAfterLogin(): boolean {
    return session.currentState !== SessionState.Loading &&
      session.currentState !== SessionState.WaitAuth;
  }

  get showBars() {
    return !system.reportView;
  }

  get mainStyle(): string {
    let style = '';
    if (!system.reportView) {
      style += 'background-image: ' + settings.company.companyUISettings.global.background + '; '
    }
    return style;
  }

  get showReporting(): boolean {
    return settings.company.companyUISettings.modules.reporting
  }

  get errorMessage(): string {
    return session.errorMessage;
  }

  get errorTimeout(): number {
    return session.errorTimeout;
  }

  get showError(): boolean {
    return session.error != null && !session.error.hidden;
  }

  set showError(value: boolean) {
    if (value === false) {
      session.pushError(null);
    }
  }

  get barsSizeStyles() {
    let style = 'margin: auto; '

    style += 'max-width: ' + settings.company.companyUISettings.view.width + 'px; '
    style += 'padding-left: ' + settings.company.companyUISettings.view.margin + 'px; '
    style += 'padding-right: ' + settings.company.companyUISettings.view.margin + 'px; '

    return style;
  }

  get toolbarElevation() {
    return settings.company.companyUISettings.header.elevation
  }

  get authorized() {
    return session.currentState === SessionState.Authorized
  }

  get phoenix() {
    return $ph
  }

  async reload() {
    session.setCurrentState(SessionState.Loading)
    await session.loadEnvironment()
    if ($ph.baseUrl()) {
      try {
        await session.loadUser()
        await session.loadSettings();

        if (!session.user.emailVerified) {
          session.setCurrentState(SessionState.NotInitialized)
        } else {
          if (session.user.checkLogon2FA) {
            this.sfaMode = User2FAModes.Logon
            this.sfaProcess = Process2fa.Check
            this.sfaRequired = false
            session.setCurrentState(SessionState.PreAuthorized)
          } else {
            await wallet.loadBaseData(session.user)
            session.setCurrentState(SessionState.Authorized)

            const required2fa: any = settings.company.companyUISettings.global.required2fa
            if (required2fa && required2fa !== User2FAModes.None) {
              if (
                (required2fa === User2FAModes.Logon && session.user.mode2Fa !== User2FAModes.Logon && session.user.mode2Fa !== User2FAModes.LogonAndSign) ||
                (required2fa === User2FAModes.Sign && session.user.mode2Fa !== User2FAModes.Sign && session.user.mode2Fa !== User2FAModes.LogonAndSign) ||
                (required2fa === User2FAModes.LogonAndSign && session.user.mode2Fa !== User2FAModes.LogonAndSign)
              ) {
                this.sfaMode = required2fa
                this.sfaProcess = session.user.mode2Fa === User2FAModes.None ? Process2fa.Init : Process2fa.Required
                this.sfaRequired = true
                this.sfaShow = true
              }
            }

            if (this.$route.hash && this.$route.hash !== '') {
              this.$router.push({ hash: '' })
            }
          }
        }
      } catch (e) {
        const err: any = e
        if (err.errorCode === 'SYS004' || err.errorCode === 'SYS005') {
          session.setCurrentState(SessionState.WaitAuth)
          $ph.info('User session expired')
        } else {
          session.pushError(err)
          if (String(err.errorCode).startsWith('HTTP')) {
            $ph.delay('FULL_RELOAD', this.errorTimeout * 2, this.reload)
          }
        }
      }
    } else {
      try {
        await session.balancer('none')
        session.setCurrentState(SessionState.WaitAuth)
      } catch (err) {
        session.pushError(err)
      }
    }
  }

  async processLogin(auth: IAuthorisation) {
    await session.balancer(auth.userLogin)
    await session.userLogon(auth)
    await session.confirmSession();
    this.reload()
  }

  async doLogin(auth: IAuthorisation) {
    this.processing = true
    try {
      await this.processLogin(auth)
    } catch (err) {
      session.pushHiddenError(err)
    }
    this.processing = false
  }

  async doRegister(register: IRegistration) {
    this.processing = true;
    try {
      await session.balancer(register.userLogin);
      await session.userRegister(register);

      const auth: IAuthorisation = {
        userLogin: register.userLogin,
        userPwd: register.userPwd,
      }

      await this.processLogin(auth)
    } catch (err) {
      session.pushError(err)
    }
    this.processing = false;
  }

  async initProfile(code: string) {
    this.processing = true;
    try {
      await session.checkEmail(code)
      window.location.reload()
    } catch (err) {
      session.pushError(err)
      this.processing = false;
    }
  }

  async forgetPass(email: string) {
    this.processing = true;
    try {
      await session.sendRecoveryEmail(email)
      session.showNotification({ message: 'system.recoveryEmailSent', type: 'info', timeout: 3000 })
      this.showForgetPass = false;
    } catch (err) {
      session.pushError(err)
    }
    this.processing = false;
  }

  async resendInitProfileCode() {
    this.processing = true;
    try {
      await session.activateEmail()
      session.showNotification({ message: 'system.activationEmailSent', type: 'info', timeout: 3000 })
    } catch (err) {
      session.pushError(err)
    }
    this.processing = false;
  }

  async logout() {
    window.location.replace(window.location.origin)
    await session.userLogout()
  }

  async refreshData() {
    if (session.currentState === SessionState.Authorized) {
      try {
        await wallet.refreshBaseData(session.user)
      } catch (err) {
        session.pushError(err)
      }
    }
  }

  refreshTransaction() {
    if (this.previewData && this.previewData.callback) {
      try {
        this.previewData.callback()
      } catch (err) {
        session.pushError(err)
      }
    }
  }

  mounted() {
    if (this.$vuetify.breakpoint.smAndDown) {
      system.setScreenSize(IScreenSize.sm)
    } else if (this.$vuetify.breakpoint.lgAndUp) {
      system.setScreenSize(IScreenSize.lg)
    } else {
      system.setScreenSize(IScreenSize.md)
    }
    this.reload();
    setInterval(this.refreshData, Number(settings.company.companyUISettings.global.refreshRate))
  }
}

